/* eslint-disable no-unused-vars */
import React from 'react';
import ERPFuzeLogo from '../assets/gif/erpFuze-logo.gif';


const Loading = ({ error, retry, timedOut, pastDelay }) => {
  const reload = () => {
    window.localStorage.setItem('gatsbyUser', JSON.stringify({}));
    window.localStorage.setItem('filter', JSON.stringify({}));
    window.localStorage.setItem('chart', JSON.stringify({}));
    window.location.reload(false);
  };

  if (error) {
    return (
      <div className="error-container">
        <img
          alt="..."
          className="error-img"
          src={require('assets/img/theme/error_empty.svg')}
        />
        <div className="error-box">
          <strong>Ooops! This Page Could Not Be Loaded</strong>
          <div className="error-message">
            {error}
          </div>
          <button className="btn btn-info" onClick={reload}>Return to login</button>
        </div>
      </div>
    );
  } else if (timedOut) {
    return (
      <div>
        Taking a long time... <button onClick={retry}>Retry</button>
      </div>
    );
  } else if (pastDelay) {
    return <div className="wrap1">
      <div className="loading1">
        <img src={ERPFuzeLogo} alt="loading..." />
      </div>
    </div>;
  } else {
    return null;
  }
};

export default Loading;
